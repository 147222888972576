import React, {useEffect, useState} from 'react';
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import axios from 'axios';
import Header from '../components/LoanUnderwrite/Header';
import Steps from '../components/LoanUnderwrite/Steps';
import UnderwriteLoan from './UnderwriteLoan.jsx';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");

const LoanUnderwriteLanding = () => {

  const [token, setToken] = useState(null);
  const [company, setCompany] = useState("");
	const [isLoading, setIsLoading] = useState(false);

  const [currentStep, setCurrentStep] = useState("loan-application");

  const [config, setConfig] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const [inputs, setInputs] = useState({});
  const [nextDisabled, setNextDisabled] = useState(true);

  let el = document.querySelectorAll('input[required]');

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(token !== null && token !== undefined){
			axios.post(serverConfig.api_base_url + "loan_underwrite_config_get", {token:token})
			.then(response=>{
				console.log('loan_underwrite_config_get response', response.data);
				if(response.data.status === 200){
					if(response.data.items !== null) {
						setConfig(response.data.items);
					} else {
						setConfig({});
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));
    }
  }, [token]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
    setInputs((stateInputs) => {
      console.log(Object.keys(stateInputs).length, Object.keys(stateInputs));
      console.log(el.length - 1);
      if(Object.keys(stateInputs).length === el.length - 1){
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
      return stateInputs;
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputs);
    if(Number(inputs.age) < 24){
      setShowAlert(true);
      setAlertMsg("Applicant should be minimum 24 years old at the time of making the application, as per company policy.");
    } else if(Number(inputs.propertyValue) < config.property_value_min || Number(inputs.propertyValue) > config.property_value_max){
      setShowAlert(true);
      setAlertMsg("Property value cannot be less than $50,000 or greater than $4,000,000 as per company policy.");
    } else if(Number(inputs.loanAmount) + Number(inputs.selfContribution) !== Number(inputs.propertyValue)){
      console.log(Number(inputs.loanAmount) + Number(inputs.selfContribution));
      setShowAlert(true);
      setAlertMsg("Sum of Loan Amount and Self Contribution does not equal Property Value.");
    } else if((Number(inputs.selfContribution)*100)/Number(inputs.propertyValue) < 5){
      setShowAlert(true);
      setAlertMsg("Loan to Value Ratio must be greater than or equal to 5% as per company policy.");
    } else if(((Number(inputs.loanAmount)*0.01)/Number(inputs.monthlyIncome)) > 0.65){
      setShowAlert(true);
      setAlertMsg("Fixed Obligation to Income Ration cannot be more than 65% as per company policy.");
    } else {
      axios.post(serverConfig.api_base_url + "loan_underwrite_set",{token:token, entity:"loan_application", items:inputs})
      .then(response=>{
        console.log("loan_underwrite_set response:", response);
        if (response.data.status === 200) {
          console.log('success');
          setCurrentStep("approval-status");
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }

    setTimeout(() => {
      setShowAlert(false);
      setAlertMsg("");
    }, 3000);
  }

  const handleReset = () => {
    let inputEl = document.querySelectorAll('input[type="radio"]');
    for(let i=0; i < inputEl.length; i++) {
      inputEl[i].checked=false;
    }
    setInputs({});
    setInputs((stateInputs) => {
      //console.log(Object.keys(stateInputs).length, Object.keys(stateInputs));
      //console.log(el.length - 1);
      if(Object.keys(stateInputs).length === el.length - 1){
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
      return stateInputs;
    })
  }

  return (<>
    {currentStep === "loan-application" && <div className='loan-underwrite'>
      {isLoading && <div id="loading"><img src={require('./../assets/images/ai.gif')} alt="Loading" width="100" /></div>}
      <Header token={token} setCompany={setCompany} />
      <Container fluid className='container-wrapper'>
        {showAlert && <div className='alert-msg'><Container fluid>{alertMsg}</Container></div>}
        <p className='mb-4 txt-gray'>Enter below details for underwriting</p>
        <Steps currentStep="Details" />
        
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm={4}>
              <Form.Group className="mb-4">
                <Form.Label>Full Name</Form.Label>
                <Form.Control type="text" placeholder='Enter name here' name="fullName" value={inputs.fullName || ""} onChange={handleChange} required />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-4">
                <Form.Label>Age</Form.Label>
                <Form.Control type="number" min="0" placeholder='Enter age here' name="age" value={inputs.age || ""} onChange={handleChange} required />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-4">
                <Form.Label>City</Form.Label>
                <Form.Control type="text" placeholder='Enter city here' name="city" value={inputs.city || ""} onChange={handleChange} required />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-4">
                <Form.Label>Occupation</Form.Label>
                <Form.Control type="text" placeholder='Enter here' name="occupation" value={inputs.occupation || ""} onChange={handleChange} required />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-4">
                <Form.Label>Occupation Tenure</Form.Label>
                <Form.Control type="number" min="0" placeholder='Enter here' name="occupationTenure" value={inputs.occupationTenure || ""} onChange={handleChange} required />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-4">
                <Form.Label>Type of loan intended</Form.Label>
                <div>
                  <Form.Check inline type="radio" label="Mortgage" id="Mortgage" name="typeOfLoanIntended" value={"Mortgage" || ""} onChange={handleChange} required className='mt-2' />
                  <Form.Check inline type="radio" label="Home Equity Loan" id="Home Equity Loan" name="typeOfLoanIntended" value={"Home Equity Loan" || ""} onChange={handleChange} required className='mt-2' />
                </div>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-4">
                <Form.Label>Monthly Income</Form.Label>
                <Form.Control type="number" min="0" placeholder='Enter here' name="monthlyIncome" value={inputs.monthlyIncome || ""} onChange={handleChange} required />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-4">
                <Form.Label>Monthly Savings</Form.Label>
                <Form.Control type="number" min="0" placeholder='Enter here' name="monthlySavings" value={inputs.monthlySavings || ""} onChange={handleChange} required />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-4">
                <Form.Label>Property Value</Form.Label>
                <Form.Control type="number" min="0" placeholder='Enter here' name="propertyValue" value={inputs.propertyValue || ""} onChange={handleChange} required />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-4">
                <Form.Label>Loan Amount</Form.Label>
                <Form.Control type="number" min="0" placeholder='Enter here' name="loanAmount" value={inputs.loanAmount || ""} onChange={handleChange} required />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-4">
                <Form.Label>Self Contribution</Form.Label>
                <Form.Control type="number" min="0" placeholder='Enter here' name="selfContribution" value={inputs.selfContribution || ""} onChange={handleChange} required />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm="4" className='d-flex justify-content-between'>
              <Button variant="transparent" className='mt-4 mb-5 w-50' type="reset" onClick={handleReset}>Reset</Button>
              <Button variant="primary" className='mt-4 mb-5 ms-3 w-50' type='submit' disabled={nextDisabled}>Underwrite Loan</Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>}
    {currentStep === "approval-status" && <UnderwriteLoan applicationInputs={inputs} />}
  </>);
}

export default LoanUnderwriteLanding;